<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>{{ $lang.student.crud.view }}</strong>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'StudentView'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.detail }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentSkill'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.skill }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentProject'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.project }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentCertificate'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.certificate }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentAward'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.award }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentLocation'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.location }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'StudentResult'">
              <CTab>
                <template slot="title">
                  {{ $lang.student.crud.result }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component :is="selected" @changeSelected="getTab($event)" style="margin-top: 20px"></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import StudentView from "./tab/StudentView";
import StudentSkill from "./tab/StudentSkill";
import StudentProject from "./tab/StudentProject";
import StudentCertificate from "./tab/StudentCertificate";
import StudentAward from "./tab/StudentAward";
import StudentResult from "./tab/StudentResult";
import StudentLocation from "./tab/StudentLocation";
import BackToList from "/src/views/backToList.vue";

export default {
  name: "Student View",
  mixins: [Mixin],
  components: {
    StudentSkill,
    StudentProject,
    StudentView,
    StudentCertificate,
    StudentAward,
    StudentLocation,
    BackToList,
    StudentResult,
  },
  data() {
    return {
      userId: "",
      tabs: ["StudentView","StudentSkill","StudentProject","StudentCertificate","StudentAward","StudentLocation","StudentResult"],
      selected: "StudentView",
      currentId: "",
      backToListFlag: "",
    };
  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;
  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
